













import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import gapiInitCredentials from '~/const/gapi-init'
// @ts-ignore
import GSignInButton from 'vue-google-signin-button'
// @ts-ignore
import FBSignInButton from 'vue-facebook-signin-button'
import { googleLogin, facebookLogin } from '~/api/auth'
import { AuthStore, IOnLoginSuccess } from '~/store/auth'

Vue.use(GSignInButton)
Vue.use(FBSignInButton)

/* global FB */
@Component
export default class FormSocialButtons extends Vue {
    /**
     * The Auth2 parameters, as seen on
     * https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams.
     * As the very least, a valid client_id must present.
     * @type {Object}
     */
    googleSignInParams = { client_id: gapiInitCredentials.clientId }
    fbSignInParams = { scope: 'email', return_scopes: true }

    @AuthStore.Action('onLoginSuccess') onLoginSuccess: (params: IOnLoginSuccess) => void

    onError(service: string) {
        this.$store.dispatch('modal/showNotification', {
            name: 'error',
            title: 'Unable to login!',
            content: `Try log in without ${service} or contact support.`
        })
    }

    /*
     * GOOGLE
     */
    async onGoogleSignInSuccess(googleUser: any) {
        // `googleUser` is the GoogleUser object that represents the just-signed-in user.
        // See https://developers.google.com/identity/sign-in/web/reference#users
        try {
            const authResponse = googleUser.getAuthResponse(true)
            const { key } = await googleLogin(authResponse.access_token)
            this.onLoginSuccess({ key })
        } catch (err) {
            this.onError('google')
        }
    }
    onGoogleSignInError(err: any) {
        console.error(err)
    }

    /*
     * FACEBOOK
     */
    onFacebookSignInSuccess() {
        // @ts-ignore
        FB.getLoginStatus(async (user: any) => {
            try {
                const { key } = await facebookLogin(user.authResponse.accessToken)
                this.onLoginSuccess({ key })
            } catch (err) {
                this.onError('facebook')
            }
        })
    }
    onFacebookSignInError(err: any) {
        console.error(err)
    }
}
