export const prettifyApiError = (err: any): string => {
	const value = err.response?.data
	if (!value) return value

	if (value && (typeof value === 'string' || value instanceof String)) {
		return err.response.data.substring(0, 200)
	} else if (value && typeof value === 'object' && value.constructor === Object) {
		return value[Object.keys(value)[0]].toString()
	}
	return err
}
