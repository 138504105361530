












































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import FormSocialButtons from '~/components/auth/FormSocialButtons.vue'
import { AuthStore, IOnLoginSuccess } from '~/store/auth'
import { login } from '~/api/auth'
import { prettifyApiError } from '~/services/prettify-api-error'
import { IS_ELITEPAGE } from '~/const/environment'
import UiLoader from '@/components/UiLoader.vue'

@Component({ components: { FormSocialButtons, UiLoader } })
export default class LoginPage extends Vue {
	isLoading = false
	email: string = ''
	password: string = ''
	loginError: string = ''
	IS_ELITEPAGE = IS_ELITEPAGE

	@AuthStore.Action('onLoginSuccess') onLoginSuccess: (params: IOnLoginSuccess) => void

	goPreviousRoute() {
		if (IS_ELITEPAGE) this.$router.back()
		// @ts-ignore
		else this.$router.push({ path: this.$router.history.current.query.redirect || '/' })
	}

	async login() {
		try {
			this.isLoading = true
			const { key } = await login({ email: this.email, password: this.password })
			this.onLoginSuccess({ key })
		} catch (err) {
			this.loginError = prettifyApiError(err) ?? 'Login failed, please try again later or contact support.'
		} finally {
			this.isLoading = false
		}
	}

	validateFields() {
		this.login()
	}
}
